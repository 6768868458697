@import "main.css";
/*---------Cartes custom----------------------*/

.btn-light:visited {
    color: inherit !important;
}

/*---------------------------------------------------------ANNULE L'EFFET DEFILEMENT SUR LE CTA*/
.cta_banner .background-image-div-opacity { background-attachment: unset !important; }

/*---------------------------------------------------------ENLEVE L'EFFET SUR LES ESPACEMENTS*/
.b-bloc-divider {
    border: none !important;
    border-width: none !important;
    box-shadow: none !important;
    background-color: #ffffff !important; 
}